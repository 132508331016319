// TODO remove next line
export const strict = false

export const state = () => {
  return {}
}

export const mutations = {}

export const actions = {}

export const getters = {}
